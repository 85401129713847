import React from "react"
import { Layout } from "@components/layout"
import { BlogHero } from "@components/blog"
import { Breadcrumbs, AnimatedLink } from "@components/shared"

import { wrapper, tags__list } from "./styles/tags.module.scss"

const Tags = ({ pageContext }) => {
  const { page, tags } = pageContext

  return (
    <Layout {...page}>
      <div className={wrapper}>
        <ul className={tags__list}>
          {tags.map(({ name, slug, count }) => (
            <li key={name}>
              <AnimatedLink to={`/tags/${slug}`}>#{name}</AnimatedLink>
              <span>{count}</span>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default Tags
